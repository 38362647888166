import React, { Component } from "react";
import stockillustration from "../Resources/Images/reading.png";
import classes from "./Landing.module.css";
import firebase from "firebase";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { Button, Radio } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Redirect } from "react-router-dom";

let redirect = <Redirect to="dashboard" />;

class Landing extends Component {
  state = {
    users: [],
  };

  uiConfig = {
    callbacks: {
      signInSuccessWithAuthResult: (authResult, redirectUrl) => {
        var user = authResult.user;
        var credential = authResult.credential;
        var isNewUser = authResult.additionalUserInfo.isNewUser;
        var providerId = authResult.additionalUserInfo.providerId;
        var operationType = authResult.operationType;

        let db = this.props.firebase.firestore();
        if (isNewUser) {
          console.log("Signing up new user!");
          console.log(user);
          console.log(user.email);
          db.collection("users")
            .doc(user.email)
            .set({
              name: user.email,
              following: [],
            })
            .then(function () {
              console.log("Document successfully written!");
            })
            .catch(function (error) {
              console.error("Error writing document: ", error);
            });
        }

        // Do something with the returned AuthResult.
        // Return type determines whether we continue the redirect automatically
        // or whether we leave that to developer to handle.
        return true;
      },
      signInFailure: (error) => {
        // Some unrecoverable error occurred during sign-in.
        // Return a promise when error handling is completed and FirebaseUI
        // will reset, clearing any UI. This commonly occurs for error code
        // 'firebaseui/anonymous-upgrade-merge-conflict' when merge conflict
        // occurs. Check below for more details on this.
        // return handleUIError(error);
      },
    },
    queryParameterForSignInSuccessUrl: "signInSuccessUrl",
    signInFlow: "popup",
    signInSuccessUrl: "", //Specifying sign in success url can cause double redirect since we are also managing redirect in react-router with local state.
    signInOptions: [
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    ],
    // Other config options...
  };

  followUser = (toFollow) => {
    let db = this.props.firebase.firestore();
    var currentUser = firebase.auth().currentUser;
    var user = db.collection("users").doc(currentUser.email);

    user.update({
      following: firebase.firestore.FieldValue.arrayUnion(toFollow),
    });
  };

  componentDidMount() {
    let db = this.props.firebase.firestore();
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        console.log(user);
        var docRef = db.collection("users").doc(user.email);

        docRef
          .get()
          .then((doc) => {
            if (doc.exists) {
              console.log("Document data:", doc.data());
              if (doc.data().following.length === 0) {
                db.collection("users")
                  .get()
                  .then((querySnapshot) => {
                    let u = [];
                    querySnapshot.forEach((doc) => {
                      // doc.data() is never undefined for query doc snapshots
                      console.log(doc.id, " => ", doc.data());
                      u.push(doc.data());
                    });
                    this.setState({ users: u });
                  });
              } else {
                console.log("Already following");
              }
            } else {
              // doc.data() will be undefined in this case
              console.log("No such document!");
            }
          })
          .catch(function (error) {
            console.log("Error getting document:", error);
          });
      } else {
        // No user is signed in.
      }
    });
  }

  render() {
    return (
      <div className={classes.contentwrapper}>
        <React.Fragment>
          <div className={classes.firstcol}>
            <div>
              <h1 className={classes.heading}>Social curated reading</h1>
            </div>

            <div>
              <p className={classes.para}>Built for curious people.</p>
            </div>
            {!this.props.loggedin ? (
              <StyledFirebaseAuth
                uiConfig={this.uiConfig}
                firebaseAuth={firebase.auth()}
                className={classes.emailbox}
              />
            ) : (
              <div>
                {this.state.users.map((u) => (
                  <div>
                    <Button
                      onClick={() => {
                        this.followUser(u);
                      }}
                      type="primary"
                      icon={<PlusOutlined />}
                    >
                      {u.first}
                    </Button>
                  </div>
                ))}
              </div>
            )}
          </div>

          <div className={classes.secondcol}>
            <img
              className={classes.stock_illustration}
              src={stockillustration}
              alt="trading illustration"
            ></img>
          </div>
        </React.Fragment>
      </div>
    );
  }
}

export default Landing;
